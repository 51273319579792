/**
 *
 * SigninPage
 *
 */

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';

import SigninForm from 'components/SigninForm/SigninForm';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import selectSigninPage from './selectors';
import reducer from './reducer';
import saga from './saga';
import { signinSubmit } from './actions';
import messages from './messages';

import { RootState } from './types';

interface OwnProps {}

interface StateProps {
  signinpage: any;
}

interface DispatchProps {
  onSubmit: (values: any) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class SigninPage extends React.PureComponent<Props> {
  public render() {
    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
          <meta property="og:title" content={`${translations(messages.header)}`} />
          <meta name="og:description" content={`${translations(messages.header)}`} />
        </Helmet>
        <SigninForm onSubmit={this.props.onSubmit} values={this.props.signinpage} />
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  signinpage: selectSigninPage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onSubmit: (values) => dispatch(signinSubmit(values)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'signinPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'signinPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(SigninPage);
