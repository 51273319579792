/*
 *
 * SigninPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const signinSubmit = (values: any) => action(ActionTypes.SIGNIN_SUBMIT, values);

export const signin = createAsyncAction(
  ActionTypes.SIGNIN_REQUEST,
  ActionTypes.SIGNIN_SUCCESS,
  ActionTypes.SIGNIN_FAILURE,
)<void, any, Error>();
