/*
 * SigninPage Messages
 *
 * This contains all the text for the SigninPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SigninPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Đăng nhập',
  },
  signup: {
    id: `${scope}.signup`,
    defaultMessage: 'Đăng ký',
  },
  rememberMe: {
    id: `${scope}.rememberMe`,
    defaultMessage: 'Ghi nhớ?',
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
    defaultMessage: 'Quên mật khẩu',
  },
  donotHasAccount: {
    id: `${scope}.donotHasAccount`,
    defaultMessage: 'Chưa có tài khoản?',
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Liên hệ với chúng tôi!',
  },
});
