import * as React from 'react';

import { LockOutlined, MailOutlined } from '@ant-design/icons';

import { Input as AInput, Form } from 'antd';
import Checkbox from '../Checkbox/Checkbox';
import { FormattedMessage } from 'react-intl';
import TrackingInput from 'components/TrackingComponents/TrackingInput';
import { Link } from 'react-router-dom';
import PageHeader from '../PageHeader/PageHeader';
import TrackingButton from 'components/TrackingComponents/Button';
import media from 'utils/mediaStyle';
import messages from 'containers/SigninPage/messages';
import styled from 'styled-components';
import topBg from './top-bg.png';
import utilsMessages from 'utils/messages';

const AntdInput = styled(AInput)<any>``;
const AntdPassword = styled(AInput.Password)<any>``;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  max-width: 720px;
  background-image: url(${topBg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8pt;
  ${media.md`
    padding: 30pt 90pt;
  `};
  p {
    line-height: normal;
    margin: 8px 0;
  }
`;

const RememberRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 24px;
`;

const SignUpButton = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 12px;
  }
`;

interface SigninFormProps {
  values?: any;
  onSubmit: (values: any) => void;
}

const SigninForm: React.FC<SigninFormProps> = ({ onSubmit, values }) => {
  const [form] = Form.useForm();
  React.useEffect(() => {
    form.setFieldsValue({
      email: values.email,
      password: values.password,
      rememberMe: values.rememberMe,
    });
  }, [values]);
  return (
    <Container>
      <PageHeader size="large">
        <FormattedMessage {...messages.header} />
      </PageHeader>
      <Form onFinish={(values) => onSubmit(values)} form={form} className="login-form" size="large" layout="vertical">
        <FormattedMessage {...utilsMessages.email}>
          {(email) => (
            <FormattedMessage {...utilsMessages.fieldEmail}>
              {(fieldEmail) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: 'Email' }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name="email"
                      colon={false}
                      required={false}
                      label="Email"
                      hasFeedback
                      rules={[
                        { type: 'email', message: fieldEmail },
                        { required: true, message: fieldRequired },
                      ]}
                      normalize={(value) => value && value.replace(/\s/g, '')}
                    >
                      <TrackingInput
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email"
                        autoComplete="username"
                        name="email"
                        options={{
                          action: 'Enter Email',
                          category: 'Signin Form | Input',
                        }}
                      >
                        <AntdInput />
                      </TrackingInput>
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.password}>
          {(password) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: password }}>
              {(fieldRequired) => (
                <Form.Item
                  name="password"
                  colon={false}
                  required={false}
                  label={password}
                  rules={[
                    {
                      required: true,
                      message: fieldRequired,
                    },
                  ]}
                >
                  <TrackingInput
                    name="password"
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    autoComplete="current-password"
                    placeholder={password}
                    options={{
                      action: 'Enter Password',
                      category: 'Signin Form | Input',
                    }}
                  >
                    <AntdPassword />
                  </TrackingInput>
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>

        <RememberRow>
          <Form.Item
            name="rememberMe"
            colon={false}
            required={false}
            valuePropName="checked"
            initialValue={true}
            noStyle={true}
          >
            <Checkbox>
              <FormattedMessage {...messages.rememberMe} />
            </Checkbox>
          </Form.Item>
          <Link to="/forgot-password">
            <FormattedMessage {...messages.forgotPassword} />
          </Link>
        </RememberRow>
        <SignUpButton>
          <TrackingButton
            block
            type="bg-primary"
            htmlType="submit"
            trackingCategory="Signin Form | Button"
            trackingAction="Submit Signin Form"
          >
            <FormattedMessage {...messages.header} />
          </TrackingButton>
        </SignUpButton>
        <Link to="/contact-us">
          <TrackingButton block type="primary" trackingCategory="Signin Form | Button" trackingAction="Contact us">
            <FormattedMessage {...messages.signup} />
          </TrackingButton>
        </Link>
      </Form>
    </Container>
  );
};

export default SigninForm;
