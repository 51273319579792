/*
 *
 * SigninPage reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  email: '',
  password: '',
  rememberMe: true,
};

// function signinPageReducer(state: ContainerState = initialState, action: ContainerActions ) {
//   switch (action.type) {
//     case ActionTypes.DEFAULT_ACTION:
//       return state;
//     default:
//       return state;
//   }
// }

// export default signinPageReducer;

export default (state: ContainerState = initialState, action: ContainerActions) => {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.SIGNIN_SUBMIT:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
